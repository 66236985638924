import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { appState } from '../../store/reducers/appReducer';
import { authState } from '../../store/reducers/authReducer';
import Segmentations from '../../utils/Segmentations';
import AppServices from '../../services/AppService';

const useInternalAds = (adskey = 'ads_creation_detail') => {
  const { userMeta, userPlan } = useSelector(authState);
  const { listAppByKeyword } = useSelector(appState);
  const currentAds = useMemo(() => {
    const adses = Object.keys(listAppByKeyword || {}).filter((key) => key.includes(adskey));
    const availableAds = adses.reduce((acc, key) => {
      const adsValue = listAppByKeyword[key].value || { conditions: {} };
      if (Segmentations.inCriteriaAdsConfigCheck({ ...adsValue?.conditions })) {
        acc.push({
          ...adsValue,
          attachment: listAppByKeyword[key].attachment,
          key,
        });
      }
      return acc;
    }, []);
    return availableAds.length > 0 ? availableAds[Math.floor(Math.random() * availableAds.length)] : null;
  }, [listAppByKeyword, adskey, userMeta, userPlan]);

  const country = AppServices.userGeoIp()?.country || userMeta?.country_code?.toLowerCase() || (typeof window !== 'undefined' ? navigator.language?.split('-')[1]?.toLowerCase() : 'us');

  const withAds = useMemo(() => !userPlan?.expire_date || (userPlan?.expire_date && new Date(userPlan?.expire_date).getTime() < Date.now()), [userPlan]);

  return { currentAds, country, withAds };
};

export default useInternalAds;
