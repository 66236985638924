import { forwardRef, memo, useMemo } from 'react';

const ImageMemo = forwardRef(({ ...props }, ref) => {
  const Component = props?.component;

  const resolveUrl = useMemo(() => {
    const oldHost = ['assemblrworld-usa.sfo2.cdn.digitaloceanspaces.com'];
    const newHost = ['assemblrworld-asset.s3.ap-southeast-1.amazonaws.com'];
    const newUrl = props.src?.replace(oldHost, newHost);
    return newUrl;
  }, [props.src]);

  // eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
  return (Component ? (
    <Component
      {...props}
      ref={ref}
      src={resolveUrl}
      fill={props.fill?.toString()}
      loading={
        props.loading || 'lazy'
      } />
  ) : (
    <img
      {...props}
      ref={ref}
      src={resolveUrl}
      loading={
        props.loading || 'lazy'
      }
      fill={props.fill?.toString()}
      alt={props.alt || 'image'}
    />
  ));
});

ImageMemo.displayName = 'ImageMemo';

export default memo(ImageMemo);
