/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  useCallback, useMemo, useRef,
} from 'react';
import useUserAgent from '../../../hooks/useUserAgent';
import ImageMemo from './ImageMemo';

function ImageResizer({
  imageComponent,
  type,
  width,
  height,
  sizes = ['auto', 'auto'],
  loading,
  src, size, onError = () => { },
  ...props
}) {
  const ImageComponent = imageComponent;
  const ref = useRef(null);

  const { screenWidth, isMobile } = useUserAgent();

  const calculateByType = (_type) => {
    switch (_type) {
      case 'creation-thumbnail':
        if (isMobile.any()) {
          return screenWidth <= 1024 ? [200, 200] : [200, 200];
        }
        return screenWidth <= 1024 ? [200, 200] : [300, 300];
      case 'creation-detail':
        if (isMobile.any()) {
          return [300, 300];
        }
        return [512, 512];
      case 'enterprise-thumbnail':
        return [236, 160];
      case 'profile':
        switch (size) {
          case 'small':
            return [60, 60];
          default: {
            if (sizes[0] !== 'auto' && sizes[1] !== 'auto') return sizes;
            return [160, 160];
          }
        }
      // case 'bundle-thumbnail':
      //   return [278, 175];
      case 'highlight-thumnail':
        return [350, 350];
      default:
        return sizes;
    }
  };

  const readyToResize = useMemo(() => {
    if (src?.includes('defaultProfilePicture')) return false;
    try {
      const allowTypes = [
        'creation-thumbnail',
        'creation-detail',
        'enterprise-thumbnail',
        'profile',
        // 'bundle-thumbnail',
        'highlight-thumnail',
        'enterprise-background',
      ];
      const allowHosts = [
        'asset.asblr.app',
        'assemblrworld-asset.s3.ap-southeast-1.amazonaws.com',
        'assemblrworld-asset.s3.amazonaws.com',
      ];
      return src && allowTypes.includes(type) && allowHosts.includes(new URL(src).host);
    } catch (e) {
      return false;
    }
    // return false;
  }, [type, src]);

  const [widthSrc, heightSrc] = useMemo(() => calculateByType(type), [type]);

  const transformImageUrl = useCallback((url) => {
    try {
      if (!url) throw new Error('No url provided');
      const oldHost = new URL(url).host;
      const newHost = 'assemblrworld-asset.s3.ap-southeast-1.amazonaws.com';
      const resizePath = `resize/${widthSrc}x${heightSrc}_`;

      const newUrl = url.replace(oldHost, newHost).replace('http://', 'https://');

      const pathParts = newUrl.split('/');
      const fileName = pathParts.pop();
      const newPath = `${pathParts.join('/')}/${resizePath}${fileName}`;
      const resizePayload = {
        url: `${pathParts.join('/')}/${fileName}`
          .replace('https://', '')
          .replace(`${newHost}/`, '').split('?')[0],
        size: `${widthSrc}x${heightSrc}`,
      };

      return {
        newUrl: newPath,
        resizePayload,
      };
    } catch (e) {
      return { newUrl: '', resizePayload: {} };
    }
  }, []);

  const { newUrl, resizePayload } = transformImageUrl(src);

  const fallback = useMemo(() => {
    switch (type) {
      case 'profile': return 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/Asset/placeholder/defaultProfilePicture.jpeg';
      case 'creation-thumbnail': return 'https://asset.asblr.app/Asset/placeholder/project_placeholder.png';
      case 'creation-detail': return 'https://asset.asblr.app/Asset/placeholder/project_placeholder.png';
      default: return null;
    }
  }, [type]);

  const resize = useCallback(async () => {
    try {
      const data = JSON.stringify(resizePayload);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://asblr.app/api/v2/creations/resize/',
        headers: { 'Content-Type': 'application/json' },
        data,
      };
      const { data: { url } } = await axios(config);
      const image = new Image();
      image.src = url;
      image.onerror = () => {
        console.error({
          key: 'resize',
          message: 'Failed to load resized image',
          data: {
            originalUrl: src,
            resizedUrl: url,
          },
        });
      };
      return url;
    } catch (error) {
      console.error({
        key: 'resize',
        message: 'Failed to resize image',
        data: {
          originalUrl: src,
        },
      });
      return src;
    }
  }, [resizePayload, src]);

  const newSource = useMemo(() => {
    const excludeHosts = [
      'digitalocean',
    ];
    if (readyToResize && !excludeHosts.includes(new URL(src).host)) {
      return newUrl;
    }
    // if (fallback && type === 'profile') {
    //   return fallback;
    // }
    return src;
  }, [readyToResize, src, fallback, type, newUrl]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <ImageMemo
      component={ImageComponent}
      ref={ref}
      width={width}
      height={height}
      src={newSource}
      style={{ opacity: 0 }}
      loading={loading}
      onLoad={(e) => {
        e.target.style.opacity = 1;
      }}
      onError={(e) => {
        e.target.style.opacity = 0;
        const currentSrc = e.target.src;
        e.target.src = '/assets/gifs/squarespinner.svg';
        if (!readyToResize) {
          if (fallback) {
            e.target.src = fallback;
          }
          e.target.style.opacity = 1;
          return true;
        }
        e.onerror = null;
        if (currentSrc !== src) {
          e.target.src = src;
          e.onerror = fallback ? () => { e.target.src = fallback; } : (_e) => {
            onError(_e);
            e.target.style.opacity = 1;
          };
          resize();
        } else if (fallback) {
          e.target.src = fallback;
        }
        e.target.style.opacity = 1;
        return true;
      }}
      {...props}
    />
  );
}

export default ImageResizer;
